import { useRef } from 'react'
import {
  AspectRatio,
  Box,
  // Image as NextImage,
  Icon,
  CSSObject,
} from '@chakra-ui/react'
import { useSpring } from 'react-spring'
import { useMove } from 'react-use-gesture'
import NextImage from 'next/image'

import {
  AnimatedAspectRatio,
  AnimatedBox,
  DEFAULT_SPRING_CONFIG,
} from '@/lib/springComponent'

import { ReactComponent as Rect13 } from '@/svg/landing/rectangle-13.svg'
import { ReactComponent as Rect14 } from '@/svg/landing/rectangle-14.svg'
import { ReactComponent as Ellipse } from '@/svg/landing/ellipse.svg'

interface Props {
  id?: string
  sx?: CSSObject
  ratio: number
  image: string | StaticImageData
  isAlternativeStyle?: boolean
}

function getTransform(rotX: number, rotY: number, scale: number) {
  return `perspective(600px) rotateX(${rotX}deg) rotateY(${rotY}deg) scale(${scale})`
}

function Image({
  id,
  sx,
  image,
  ratio,
  isAlternativeStyle,
}: Props): React.ReactElement {
  const imageContainerRef = useRef<HTMLDivElement>(null)

  const [{ transform }, springApi] = useSpring(() => ({
    transform: getTransform(0, 0, 1),
    config: DEFAULT_SPRING_CONFIG,
  }))

  const bind = useMove(({ xy: [mx, my], moving }) => {
    if (moving && imageContainerRef.current) {
      const { x, y, width, height } =
        imageContainerRef.current.getBoundingClientRect()

      springApi.start({
        transform: getTransform(
          (-(my - y - height / 2) / 50).clamp(-5, 5),
          ((mx - x - width / 2) / 50).clamp(-5, 5),
          1.05,
        ),
      })
    }
  })

  return (
    <AspectRatio
      id={id}
      ratio={ratio}
      sx={{
        overflow: 'visible !important',
        '& >*:not(style)': {
          overflow: 'visible !important',
        },
        maxW: '340px',
        w: '100%',
        justifySelf: 'center',
        ...sx,
      }}
    >
      <Box
        sx={{ position: 'relative', w: 'full', h: 'full', overflow: 'visible' }}
      >
        <Icon
          as={Ellipse}
          boxSize="36%"
          sx={{
            stroke: isAlternativeStyle ? '#E6E6E6' : '#415E80',
            position: 'absolute',
            bottom: 0,
            right: isAlternativeStyle ? undefined : 0,
            left: isAlternativeStyle ? 0 : undefined,
            transform: `translate3d(${
              isAlternativeStyle ? '-50%' : '50%'
            }, -50%, 0)`,
          }}
        />
        <Icon
          as={Rect14}
          boxSize="30%"
          sx={{
            stroke: '#FFCF87',
            position: 'absolute',
            bottom: 0,
            right: isAlternativeStyle ? undefined : 0,
            left: isAlternativeStyle ? 0 : undefined,
            transform: isAlternativeStyle ? 'rotateY(180deg)' : 'none',
          }}
        />
        <Icon
          as={Rect13}
          boxSize="16%"
          sx={{
            stroke: '#FFD53F',
            position: 'absolute',
            top: 0,
            right: isAlternativeStyle ? 0 : undefined,
            left: isAlternativeStyle ? undefined : 0,
            transform: isAlternativeStyle ? 'rotateY(180deg)' : 'none',
          }}
        />
        <AnimatedBox
          id={id}
          ref={imageContainerRef}
          {...bind()}
          onMouseLeave={() => {
            springApi.start({
              transform: getTransform(0, 0, 1),
            })
          }}
          sx={{
            position: 'absolute',
            w: '84%',
            top: '8%',
            left: '50%',
            transform: 'translate3d(-50%, 0%, 0)',
          }}
        >
          <AnimatedAspectRatio
            ratio={ratio}
            style={{ transform }}
            sx={{ w: 'full', h: 'full' }}
          >
            <NextImage
              src={image}
              alt=""
              layout="fill"
              objectFit="cover"
              placeholder="blur"
            />
          </AnimatedAspectRatio>
        </AnimatedBox>
      </Box>
    </AspectRatio>
  )
}

export default Image
