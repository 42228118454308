import { Text, VStack, CSSObject } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'

interface Props {
  sx?: CSSObject
}

function Heading({ sx }: Props): React.ReactElement {
  const { t } = useTranslation('landing')

  return (
    <VStack
      spacing="-4px"
      sx={{
        alignSelf: {
          base: 'center',
          md: 'flex-start',
        },
        alignItems: {
          base: 'center',
          md: 'flex-start',
        },
        color: 'inherit',
        fontSize: '2xl',
        ...sx,
      }}
    >
      <Text sx={{ fontWeight: 'medium' }}>{t('ourTeam.name')}</Text>
      <Text>{t('ourTeam.position')}</Text>
    </VStack>
  )
}

export default Heading
