import { Text, VStack, Box } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'

import FAQsList, {
  Props as FAQsProps,
} from '@/features/landing/components/FAQs'

function FAQs({ items }: FAQsProps): React.ReactElement {
  const { t } = useTranslation('faqs')
  return (
    <Box
      as="section"
      id="blogs"
      sx={{
        bg: 'gray.50',
        w: 'full',
      }}
    >
      <VStack
        spacing={{
          base: 4,
          md: 8,
        }}
        layerStyle="container"
      >
        <VStack
          spacing="1"
          layerStyle="landingSectionHeading"
          sx={{
            alignItems: 'center',
          }}
        >
          <Text as="h1" textStyle="landingSectionTitle">
            {t('title')}
          </Text>
        </VStack>
        <FAQsList items={items} />
      </VStack>
    </Box>
  )
}

export default FAQs
