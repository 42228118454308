import { useMemo } from 'react'
import { Text, VStack, UnorderedList, ListItem } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'

import { Item } from '@/features/landing/components/FAQs'

export const TEXT_STYLE = {
  fontSize: {
    base: 'md',
    md: 'lg',
  },
}

export const LIST_STYLE = {
  ...TEXT_STYLE,
  listStylePosition: 'inside',
  listStyleType: 'none',
  '& li': {
    display: 'flex',
  },
  '& li::before': {
    content: '"•"',
    display: 'inline',
    pr: 2,
  },
}

export const useFAQsData = (): Item[] => {
  const {
    t,
    i18n: { language },
  } = useTranslation('faqs')

  return useMemo(
    () => [
      {
        title: t('FAQs.0.question'),
        content: <Text sx={TEXT_STYLE}>{t('FAQs.0.answer.paragraph')}</Text>,
      },
      {
        title: t('FAQs.1.question'),
        content: <Text sx={TEXT_STYLE}>{t('FAQs.1.answer.paragraph')}</Text>,
      },
      {
        title: t('FAQs.2.question'),
        content: (
          <VStack sx={{ alignItems: 'stretch' }}>
            <Text sx={TEXT_STYLE}>{t('FAQs.2.answer.paragraph')}</Text>
            <UnorderedList sx={LIST_STYLE}>
              <ListItem>{t('FAQs.2.answer.list.0')}</ListItem>
              <ListItem>{t('FAQs.2.answer.list.1')}</ListItem>
              <ListItem>{t('FAQs.2.answer.list.2')}</ListItem>
            </UnorderedList>
          </VStack>
        ),
      },
      {
        title: t('FAQs.3.question'),
        content: (
          <VStack spacing="4" sx={{ alignItems: 'stretch' }}>
            <Text sx={TEXT_STYLE}>{t('FAQs.3.answer.paragraph.0')}</Text>
            <Text sx={TEXT_STYLE}>{t('FAQs.3.answer.paragraph.1')}</Text>
          </VStack>
        ),
      },
      {
        title: t('FAQs.4.question'),
        content: <Text sx={TEXT_STYLE}>{t('FAQs.4.answer.paragraph')}</Text>,
      },
      {
        title: t('FAQs.5.question'),
        content: <Text sx={TEXT_STYLE}>{t('FAQs.5.answer.paragraph')}</Text>,
      },
      {
        title: t('FAQs.7.question'),
        content: <Text sx={TEXT_STYLE}>{t('FAQs.7.answer.paragraph')}</Text>,
      },
      {
        title: t('FAQs.8.question'),
        content: <Text sx={TEXT_STYLE}>{t('FAQs.8.answer.paragraph')}</Text>,
      },
      {
        title: t('FAQs.9.question'),
        content: <Text sx={TEXT_STYLE}>{t('FAQs.9.answer.paragraph')}</Text>,
      },
      {
        title: t('FAQs.10.question'),
        content: (
          <VStack spacing="4" sx={{ alignItems: 'stretch' }}>
            <Text sx={TEXT_STYLE}>{t('FAQs.10.answer.paragraph.0')}</Text>
            <UnorderedList sx={LIST_STYLE}>
              <ListItem>{t('FAQs.10.answer.list.0')}</ListItem>
              <ListItem>{t('FAQs.10.answer.list.1')}</ListItem>
              <ListItem>{t('FAQs.10.answer.list.2')}</ListItem>
            </UnorderedList>
            <Text sx={TEXT_STYLE}>{t('FAQs.10.answer.paragraph.1')}</Text>
          </VStack>
        ),
      },
      {
        title: t('FAQs.11.question'),
        content: <Text sx={TEXT_STYLE}>{t('FAQs.11.answer.paragraph')}</Text>,
      },
    ],
    [language],
  )
}
