import { VStack, Box, Text, AspectRatio } from '@chakra-ui/react'
import Image from 'next/image'
import { useTranslation, Trans } from 'next-i18next'
import { useSpring } from 'react-spring'
import { useInView } from 'react-intersection-observer'

import { AnimatedStack, DEFAULT_SPRING_CONFIG } from '@/lib/springComponent'

function OurTrustingClient(): React.ReactElement {
  const { t } = useTranslation('landing')

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.4,
  })

  const spring = useSpring({
    to: {
      transform: inView ? 'translate3d(0, 0px, 0)' : 'translate3d(0, 32px, 0)',
      opacity: inView ? 1 : 0,
    },
    config: DEFAULT_SPRING_CONFIG,
  })
  return (
    <Box
      as="section"
      id="reference"
      ref={ref}
      sx={{
        w: 'full',
      }}
    >
      <AnimatedStack
        style={spring}
        layerStyle="container"
        direction="column"
        spacing="40px"
        sx={{
          borderBottom: '1px solid',
          borderColor: 'gray.300',
          alignItems: 'stretch',
        }}
      >
        <VStack layerStyle="landingSectionHeading">
          <Text as="h1" textStyle="landingSectionTitle">
            {t('ourTrustingClient.title')}
          </Text>
        </VStack>

        <VStack>
          <Box
            sx={{
              maxW: '737px',
              maxH: '313px',
              w: '100%',
            }}
          >
            <AspectRatio ratio={737 / 313}>
              <Image
                src="/images/clients/trustingClient.svg"
                alt=""
                objectFit="contain"
                layout="fill"
              />
            </AspectRatio>
          </Box>
        </VStack>
        <VStack
          spacing="8px"
          mt={{
            base: '40px !important',
            sm: '80px !important',
            md: '78px !important',
          }}
        >
          <Text
            sx={{ fontWeight: 'medium' }}
            fontSize={{ base: 'xl', sm: '2xl', md: '3xl' }}
            textAlign="center"
          >
            <Trans t={t} i18nKey="ourTrustingClient.more">
              และอีก
              <Text
                as="strong"
                sx={{ fontWeight: 'medium', color: 'primary.400' }}
              >
                มากกว่า 50+ ธุรกิจ
              </Text>
              ทั้งลูกค้าองค์กรขนาดเล็กและ ขนาดใหญ่ที่ไว้วางใจ PDPA Core
            </Trans>
          </Text>
          <Text
            fontSize={{ base: 'lg', sm: 'xl', md: '2xl' }}
            fontWeight="normal"
            textAlign="center"
          >
            {t('ourTrustingClient.desc')}
          </Text>
        </VStack>
      </AnimatedStack>
    </Box>
  )
}

export default OurTrustingClient
