import {
  Text,
  VStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react'

export interface Item {
  title: string
  content: React.ReactNode
}

export interface Props {
  items: Item[]
}

function FAQsList({ items }: Props): React.ReactElement {
  return (
    <Accordion
      as={VStack}
      allowToggle
      allowMultiple
      colorScheme="whiteAlpha"
      spacing={{
        base: 4,
        sm: 6,
        md: 8,
      }}
      sx={{ alignSelf: 'stretch' }}
    >
      {items.map(({ title, content }, index) => {
        return (
          <AccordionItem
            key={`${index}`}
            sx={{
              borderRadius: '2xl',
              overflow: 'hidden',
              border: 'none',
              bg: 'white',
              w: 'full',
              py: {
                base: 4,
                md: 6,
              },
              px: {
                base: 4,
                sm: 6,
                md: 8,
              },
              '& > *': {
                borderRadius: '2xl',
              },
            }}
          >
            <AccordionButton
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                outline: 'none !important',
                boxShadow: 'none !important',
                '&:hover': {
                  bg: 'white',
                  color: 'primary.400',
                },
              }}
            >
              <Text
                as="h2"
                sx={{
                  fontSize: {
                    base: 'md',
                    sm: 'lg',
                    md: 'xl',
                  },
                  textAlign: 'left',
                }}
              >
                {title}
              </Text>
              <AccordionIcon
                boxSize={{
                  base: 6,
                  md: 8,
                }}
                sx={{
                  ml: 4,
                }}
              />
            </AccordionButton>
            <AccordionPanel pb={4}>{content}</AccordionPanel>
          </AccordionItem>
        )
      })}
    </Accordion>
  )
}

export default FAQsList
