import {
  Text,
  VStack,
  UnorderedList,
  ListItem,
  CSSObject,
} from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'

interface Props {
  sx?: CSSObject
  isAlternativeStyle?: boolean
  hideCertificates?: boolean
  hideExperience?: boolean
  hideEducation?: boolean
}

const sectionStyle = {
  alignItems: 'flex-start',
}

const sectionHeaderStyle = {
  color: 'gray.600',
  fontSize: {
    base: 'lg',
    sm: '2xl',
  },
  fontWeight: 'medium',
}

const sectionHeaderAlternateStyle = {
  ...sectionHeaderStyle,
  color: 'inherit',
}

const sectionListStyle = {
  fontSize: 'lg',
  listStylePosition: 'inside',
  listStyleType: 'none',
  '& li': {
    maxWidth: '508px',
    display: 'flex',
  },
  '& li::before': {
    content: '"•"',
    display: 'inline',
    pr: 2,
  },
}

function Content({
  sx,
  isAlternativeStyle,
  hideCertificates,
  hideExperience,
  hideEducation,
}: Props): React.ReactElement {
  const { t } = useTranslation('landing')

  return (
    <VStack
      as="article"
      spacing="4"
      sx={{
        color: 'inherit',
        alignItems: 'flex-start',
        ...sx,
      }}
    >
      {!hideExperience && (
        <VStack as="section" spacing="0" sx={sectionStyle}>
          <Text
            sx={
              isAlternativeStyle
                ? sectionHeaderAlternateStyle
                : sectionHeaderStyle
            }
          >
            {t('ourTeam.experience.title')}
          </Text>
          <UnorderedList sx={sectionListStyle}>
            <ListItem>{t('ourTeam.experience.contents.0')}</ListItem>
            <ListItem>{t('ourTeam.experience.contents.1')}</ListItem>
            <ListItem>{t('ourTeam.experience.contents.2')}</ListItem>
          </UnorderedList>
        </VStack>
      )}
      {!hideEducation && (
        <VStack as="section" spacing="0" sx={sectionStyle}>
          <Text
            sx={
              isAlternativeStyle
                ? sectionHeaderAlternateStyle
                : sectionHeaderStyle
            }
          >
            {t('ourTeam.education.title')}
          </Text>
          <UnorderedList sx={sectionListStyle}>
            <ListItem>{t('ourTeam.education.contents.0')}</ListItem>
          </UnorderedList>
        </VStack>
      )}
      {!hideCertificates && (
        <VStack as="section" spacing="0" sx={sectionStyle}>
          <Text
            sx={
              isAlternativeStyle
                ? sectionHeaderAlternateStyle
                : sectionHeaderStyle
            }
          >
            {t('ourTeam.certificate.title')}
          </Text>
          <UnorderedList sx={sectionListStyle}>
            <ListItem>{t('ourTeam.certificate.contents.0')}</ListItem>
            <ListItem>{t('ourTeam.certificate.contents.1')}</ListItem>
            <ListItem>{t('ourTeam.certificate.contents.2')}</ListItem>
            <ListItem>{t('ourTeam.certificate.contents.3')}</ListItem>
            <ListItem>{t('ourTeam.certificate.contents.4')}</ListItem>
          </UnorderedList>
        </VStack>
      )}
    </VStack>
  )
}

export default Content
