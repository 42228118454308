import Head from 'next/head'
import { useRouter } from 'next/router'

import { LOCALES, DEFAULT_LOCALE } from '@/lib/localization/constants'
import { Language } from '@/lib/localization/types'
import { removeQueryStringAndHash } from '@/lib/utils/format/url'
import env from '@/lib/env'

type Props = {
  locales?: Language[]
}

export function AlternateLink({ locales }: Props): React.ReactElement {
  const router = useRouter()

  const asPathWithoutQuery = removeQueryStringAndHash(router.asPath)

  return (
    <Head>
      {(locales || LOCALES).map((lang) => {
        return (
          <link
            key={lang}
            rel="alternate"
            hrefLang={lang}
            href={`${env.APP_URL}/${lang}${asPathWithoutQuery}`}
          />
        )
      })}
      <link
        rel="alternate"
        href={`${env.APP_URL}/${DEFAULT_LOCALE}${asPathWithoutQuery}`}
        hrefLang="x-default"
      />
    </Head>
  )
}
